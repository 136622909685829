<template>
 <div class="my-box" v-loading="loading">
  <!-- 功能区域 -->
  <el-row>
   <el-card shadow="always">
    <el-form label-width="80px" class="topS" :model="searchForm" ref="searchForm" :rules="myrules">
     <el-row :gutter="10">
      <el-col :span="4">
       <el-form-item label="品牌" >
        <el-select v-model="searchForm.brandId" @change="selectOne" clearable filterable placeholder="请选择">
         <el-option v-for="(item, index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
        </el-select>
       </el-form-item>
      </el-col>
      <el-col :span="4">
       <el-form-item label="酒店">
        <el-select v-model="searchForm.hotelId" clearable filterable placeholder="请选择">
         <el-option v-for="(item, index) in hotels" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
       </el-form-item>
      </el-col>
      <el-col :span="4">
       <el-form-item label="房间号">
        <el-input placeholder="请输入房间号" v-model="searchForm.roomNumber" clearable class="my-input"></el-input>
       </el-form-item>
      </el-col>
      <el-col :span="6">
       <el-form-item label="触发时间" prop="createTime">
        <el-date-picker v-model="searchForm.createTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" @change="changeTime"></el-date-picker>
       </el-form-item>
      </el-col>
      <el-col :span="6">
       <div class="grid-content reset-button">
        <el-button @click="down('searchForm')">导出</el-button>
        <el-button type="primary" @click="search('searchForm')">查询</el-button>
       </div>
      </el-col>
     </el-row>
    </el-form>
   </el-card>
  </el-row>
  <!-- 内容区域 -->
  <el-row>
   <el-card shadow="always">
    <div class="my-header-table">
     <el-table ref="multipleTable" :data="list" tooltip-effect="dark" :height="height" style="width: 100%">
      <!-- <el-table-column type="selection" width="55"></el-table-column> -->
      <el-table-column prop="hotelId" label="酒店ID" width="80"></el-table-column>
      <el-table-column prop="hotelName" label="酒店名"></el-table-column>
      <el-table-column prop="roomNumber" label="房间号"></el-table-column>
      <!-- <el-table-column prop="checkinDate" label="入住时间" sortable></el-table-column> -->
      <el-table-column prop="startTime" label="开始时间" sortable></el-table-column>
      <el-table-column prop="endTime" label="结束时间" sortable></el-table-column>
      <el-table-column prop="duration" label="时长" sortable>
       <template slot-scope="scope">
        <div>
         {{ getLongTime(scope.row.duration) }}
        </div>
       </template>
      </el-table-column>
      <el-table-column prop="feifangTime" label="日期" sortable></el-table-column>
      <!-- <el-table-column prop="ckDate" label="插卡时间" sortable></el-table-column>
            <el-table-column prop="bkDate" label="拔卡时间" sortable></el-table-column>
            <el-table-column prop="checkoutDate" label="退房时间" sortable></el-table-column> -->
     </el-table>
     <el-pagination background layout="prev, pager, next, sizes, total, jumper" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :current-page="pageNo" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange"></el-pagination>
    </div>
   </el-card>
  </el-row>
 </div>
</template>
<script>
// import axios from "axios";
import {
 feifangList,
 getBrandSelect,
 getHotelSelect,
 exportfeifangList,
} from "@/api";
import { getDay, getLongTime } from "@/api/common.js";
export default {
 name: "feifang",
 data() {
  return {
   myrules: {
    brandId: [{ required: true, message: "请选择", trigger: "change" }],
    hotelId: [{ required: true, message: "请选择", trigger: "change" }],
    createTime: [{ required: true, message: "请选择", trigger: "change" }],
   },
   searchForm: {
    roomNumber: undefined,
    brandId: undefined,
    hotelId: undefined,
    createTime: null,
   },
   height: null,
   loading: false,
   pageNo: 1,
   pageSize: 10,
   total: 0,
   hotels: [],
   brandSelectData: [],
   defaultTime: ["00:00:00", "23:59:59"],
   query: {
    roomNumber: undefined,
    brandId: undefined,
    hotelId: undefined,
    startTime: undefined,
    endTime: undefined,
   },
   list: [],
  };
 },
 created() {
  this.initTime();
  this.initialize();
 },
 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 250;
 },
 methods: {
  getLongTime(val) {
   return getLongTime(val);
  },
  async initialize() {
   try {
    let res = await getBrandSelect();
    if (res.status === 200) {
     this.brandSelectData = res.data.data;
     if (this.brandSelectData.length) {
      this.searchForm.brandId = this.brandSelectData[0].id;
      this.initSelectOne(this.searchForm.brandId);
     }
    }
   } catch (error) {}
  },
  // 获取门店下拉框
  async initSelectOne(id) {
   this.searchForm.hotelId = null;
   try {
    let res = await getHotelSelect(id);
    if (res.data.data) {
     this.hotels = res.data.data;
     if (this.hotels.length) {
      this.searchForm.hotelId = this.hotels[0].id;
      this.init();
     }
    } else {
     this.$message({
      message: "该品牌下没有门店数据",
      type: "warning",
     });
    }
   } catch (error) {}
  },
  async selectOne(id) {
   this.searchForm.hotelId = null;
   try {
    let res = await getHotelSelect(id);
    // .then((res) => {
    // console.log(res)
    if (res.data.data) {
     this.hotels = res.data.data;
     if (this.hotels.length) {
      this.searchForm.hotelId = this.hotels[0].id;
     }
    } else {
     this.$message({
      message: "该品牌下没有门店数据",
      type: "warning",
     });
    }
   } catch (error) {}
  },
  initTime() {
   let startTime = getDay(-30),
    endTime = getDay();
   this.query.startTime = startTime;
   this.query.endTime = endTime;
   this.searchForm.createTime = [startTime, endTime];
  },
  changeTime(val) {
   if (val) {
    this.query.startTime = val[0];
    this.query.endTime = val[1];
   } else {
    this.query.startTime = null;
    this.query.endTime = null;
   }
  },
  initSearchData() {
   this.query.roomNumber = this.searchForm.roomNumber;
   this.query.brandId = this.searchForm.brandId;
   this.query.hotelId = this.searchForm.hotelId;
   this.changeTime(this.searchForm.createTime);
  },
  init() {
   this.loading = true;
   this.initSearchData();
   feifangList(this.pageNo, this.pageSize, this.query)
    .then((res) => {
     this.loading = false;
     this.total = res.data.data.total;
     this.pageNo = res.data.data.pageNo;
     this.pageSize = res.data.data.pageSize;
     this.list = res.data.data.rows;
    })
    .catch((err) => {
     this.loading = false;
    });
  },
  search(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.init();
    } else {
     return false;
    }
   });
  },
  down(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     exportfeifangList(this.pageNo, this.pageSize, this.query).then((res) => {
      console.log(res);
      var blob = new Blob([res.data], {
       type: "application/vnd.ms-excel",
      });
      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob); // 创建下载的链接
      downloadElement.href = href;
      downloadElement.download = "飞房报表" + ".xlsx"; // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(href); // 释放掉blob对象
     });
    } else {
     return false;
    }
   });
  },
  handleSizeChange(psize) {
   this.pageSize = psize;
   this.init();
  },
  handleCurrentChange(val) {
   this.pageNo = val;
   this.init();
  },
 },
};
</script>
<style lang="scss" scoped>
.my-box {
 .my-iemt {
  span {
   font-size: 14px;
  }

  white-space: nowrap;

  .my-input {
   max-width: 200px;
  }
 }

 .el-row {
  margin-bottom: 10px;

  &:last-child {
   margin-bottom: 0;
  }
 }

 .el-col {
  border-radius: 4px;
 }

 .my-input {
  max-width: 209px;
 }

 .grid-content {
  border-radius: 4px;
  height: 36px;
  white-space: nowrap;

  span {
   font-size: 14px;
  }
 }


}
</style>